.about-us-wrapper {
    height: auto;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    background: var(--eggshell);
}

.about-us-inner-wrap {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
}

.contact-us-wrapper {
    /* top:0;
    left: 0;
    position: absolute; */
    
    
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    overflow: hidden;
    /* background: linear-gradient(45deg, #ff9c9c, rgba(254, 202, 202, 0.7),  rgba(254, 202, 202, 0.7), #ff9c9c) */
    background: #fff;
    /* background: rgba(0, 0, 0, 0.6); */
    
}

.contact-info-wrap {
    /* width: 350px;
    height: 425px; */
    /* height: 400px; */

    max-width: 350px;
    max-height: 500px;
    min-width: 350px;
    border: #000 1px solid;
    box-shadow: 2px 4px 6px 0 #00000085;
    /* background-color: rgba(255, 224, 224, 0.7); */
    background: var(--eggshell);
    backdrop-filter: blur(10px);
    margin: auto 0;
    padding: 15px;
    transition: 0.3s ease-in-out;
}

.contact-input {
    color: #fff;
    border: 1px solid #ffffff;
    background-color: #ff4848;
}


.logo-wrap {
    margin: 40px;
    transform: translateY(-25px);
}

.logo-about {
    width: 125px;
    margin: 10px auto;
    /* height: 36px; */
    /* height: 100%; */
    /* margin: 10px auto; */
    /* margin: auto; */
}


.copywrite-text-wrap {
    width: 40%;
    min-width: 60%;
    max-width: 60%;
    height: 100%;
    gap: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.copywrite-sec {
    /* margin: auto 0 auto 25%; */
    box-sizing: border-box;
    white-space: normal;
    background-color: #1b1b1b9a; 
    background: linear-gradient(90deg, #80808080, #cecece80, #80808080);
    background: none;
    /* background: #FFF; */
    /* box-shadow: 2px 4px 6px 0 #0000002a; */
    width: 100%;
    line-height: 1.6; 
    /* margin: auto; */

    /* border-radius: 8px; */
    /* border: 1px solid #00000021; */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    /* padding: 25px; */





    /* padding-top: 75px;
    padding-bottom: 75px; */
}

.redden {
    color: #000;
    font-weight: bold;
}

.about-us-button {
    text-align: center;
    padding: 6px 18px;
    background-color: #ffffff;
    border: 1px solid #000;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6; 
    margin: 16px auto 0px auto;
    /* width: 100px; */
    transition: 0.2s ease-in-out;
}

.contact-us-button {
    text-align: center;
    padding: 6px 18px;
    background-color: #ffffff;
    border: 1px solid #000;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6; 
    margin: 20px auto 30px auto;
    /* width: 100px; */
    transition: 0.2s ease-in-out;
}

.contact-us-button:hover {
    background-color: #000;
    color: var(--eggshell);
}

.contact-submit {
    text-align: center;
    padding: 6px 18px;
    background-color: #ffffff;
    border: 1px solid #000;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6; 
    /* width: 100px; */
    transition: 0.2s ease-in-out;
}

.contact-submit:hover {
    background-color: black;
    color: var(--eggshell);
}

.contact-info {
    /* background: rgba(255, 179, 179, 0.616); */
    background: #f3f3f3;
    backdrop-filter: blur(10px);
    box-shadow: 2px 2px 6px #0000005e;
}

.bottom-close {
    position: absolute;
    bottom: 0;
    left: 0;

    /* background-color: white; */
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.close-button {
    border: 1px solid var(--eggshell);
    background-color: #ffffff94;
    width: 100px;
    border-radius: 3px;
    margin: 10px 0;
    transition: 0.2s ease-in-out;
}

.close-button:hover {
    background-color: var(--eggshell);
    color: #fff;
}

.background-animate {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    overflow: hidden;
}

@media only screen and (max-width: 1000px) {
    .about-us-wrapper {
        height: auto;
    }

    .about-us-inner-wrap {
        justify-content: start;
    }

    .copywrite-text-wrap {
        margin-left: 10%;
    }
}

@media only screen and (max-width: 785px) {
    .about-us-wrapper {
        height: auto;
        width: 100%;
    }

    .about-us-inner-wrap {
        justify-content: center;
    }

    .copywrite-text-wrap {
        margin-left: 0;
        max-width: 100%;
        width: 90%;
    }

    .logo-about {
        width: 75px;
    }

    .contact-info-wrap {
        /* margin: auto; */
    }

}

@media only screen and (max-width: 450px) {
    .background-animate {
        display: none;
    }

    .contact-us-wrapper {
        max-height: none;
        height: auto;
    }

    .contact-info-wrap {
        /* margin: 60px auto 0 auto; */
    }

    .contact-info {
        width: 100%;
        max-width: fit-content;
    }
    .copywrite-text-wrap {
        gap: 20px;
    }
}

@media only screen and (max-width: 350px) {
    .contact-info-wrap {
        height: auto;
        /* margin: 0 10px; */
    }

}







:root {
    /* Add these styles to your global stylesheet, which is used across all site pages. You only need to do this once. All elements in the library derive their variables and base styles from this central sheet, simplifying site-wide edits. For instance, if you want to modify how your h2's appear across the site, you just update it once in the global styles, and the changes apply everywhere. */
    --primary: #ff6a3e;
    --primaryLight: #ffba43;
    --secondary: #ffba43;
    --secondaryLight: #ffba43;
    --headerColor: #1a1a1a;
    --bodyTextColor: #4e4b66;
    --bodyTextColorWhite: #fafbfc;
    /* 13px - 16px */
    --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
    /* 31px - 49px */
    --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
    --bodyFontSize: 1rem;
    /* 60px - 100px top and bottom */
    --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
}

body {
    margin: 0;
    padding: 0;
}

*, *:before, *:after {
    /* prevents padding from affecting height and width */
    box-sizing: border-box;
}
.cs-topper {
    font-size: var(--topperFontSize);
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: inherit;
    letter-spacing: .1em;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 0.25rem;
    display: block;
}

.cs-title {
    font-size: var(--headerFontSize);
    font-weight: 900;
    line-height: 1.2em;
    text-align: inherit;
    max-width: 43.75rem;
    margin: 0 0 1rem 0;
    color: var(--headerColor);
    position: relative;
}

.cs-text {
    font-size: var(--bodyFontSize);
    line-height: 1.5em;
    text-align: inherit;
    width: 100%;
    max-width: 40.625rem;
    margin: 0;
    color: var(--bodyTextColor);
}
                            

/*-- -------------------------- -->
<---            CTA             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #cta-1192 {
        margin-top: 1.875rem;
        padding: var(--sectionPadding);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        position: relative;
        z-index: 1;
    }
    #cta-1192:before {
        /* yellow shape */
        content: "";
        width: 12.25rem;
        /* 64px - 284px */
        height: clamp(4rem, 10vw, 17.75rem);
        background: var(--secondary);
        opacity: 1;
        position: absolute;
        display: block;
        top: 1.875rem;
        left: 0;
        z-index: 2;
        transform: skew(-17deg);
    }
    #cta-1192:after {
        /* covers the rest of the :before shape on the left */
        content: "";
        width: 6.25rem;
        /* 64px - 284px */
        height: clamp(4rem, 10vw, 17.75rem);
        background: var(--secondary);
        opacity: 1;
        position: absolute;
        display: block;
        top: 1.875rem;
        left: 0;
        z-index: 1;
    }
    #cta-1192 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        padding: var(--sectionPadding);
        /* 96px - 120px */
        padding-top: clamp(6rem, 20vw, 7.5rem);
        /* 100px - 240px */
        padding-bottom: clamp(6.25rem, 25vw, 15rem);
        /* prevents padding and border from affecting height and width */
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
        position: relative;
        z-index: 2;
    }
    #cta-1192 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
    }

    #cta-1192 .cs-title {
        max-width: 17ch;
        /* 28px - 40px */
        margin: 0 0 clamp(1.75rem, 3vw, 2.5rem);
        color: var(--bodyTextColorWhite);
    }
    #cta-1192 .cs-button-solid {
        font-size: 1rem;
        /* 46px - 56px */
        line-height: clamp(2.875rem, 5.5vw, 3.5rem);
        text-decoration: none;
        font-weight: 700;
        text-align: center;
        margin: 0;
        color: #fff;
        min-width: 9.375rem;
        padding: 0 2rem;
        background-color: var(--primary);
        display: inline-block;
        position: relative;
        z-index: 1;
        /* prevents padding from adding to the width */
        box-sizing: border-box;
    }
    #cta-1192 .cs-button-solid:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 0%;
        background: #000;
        opacity: 1;
        top: 0;
        left: 0;
        z-index: -1;
        transition: width 0.3s;
    }
    #cta-1192 .cs-button-solid:hover:before {
        width: 100%;
    }
    #cta-1192 .cs-image {
        width: 100%;
        /* changes to 779px at large desktop */
        max-width: 25.9375rem;
        max-height: 30.125rem;
        height: auto;
        display: block;
        position: relative;
        z-index: 10;
    }
    #cta-1192 .cs-image img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    #cta-1192 .cs-graphic-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #1a1a1a;
        display: block;
        position: absolute;
        /* same as --secitonPadding variable */
        top: clamp(3.75rem, 7.82vw, 6.25rem);
        left: 0;
        z-index: 1;
    }
    #cta-1192 .cs-graphic {
        width: 140vw;
        height: auto;
        position: absolute;
        bottom: 10%;
        left: 50%;
        z-index: -1;
        transform: translateX(-60%) rotate(-10deg);
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #cta-1192 {
        overflow: visible;
    }
    #cta-1192 .cs-container {
        flex-direction: row;
        overflow: hidden;
    }
    #cta-1192 .cs-content {
        text-align: left;
        width: 50%;
        max-width: 31.25rem;
        align-items: flex-start;
    }
    #cta-1192 .cs-image {
        flex: none;
        position: absolute;
        top: 5rem;
        right: 0;
    }
    #cta-1192 .cs-graphic {
        width: 90vw;
        max-width: 91.375rem;
        margin-left: -15.625rem;
        left: 50%;
        transform: rotate(-10deg);
    }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    #cta-1192 {
        overflow: hidden;
    }
    #cta-1192 .cs-container {
        overflow: visible;
    }
    #cta-1192 .cs-image {
        width: 48vw;
        max-width: 48.6875rem;
    }
    #cta-1192 .cs-graphic {
        width: 80vw;
    }
}
/* Large Desktop 1300px */
@media only screen and (min-width: 81.25rem) {
    #cta-1192 {
        overflow: hidden;
    }
    #cta-1192:before {
        height: 17.6875rem;
        width: 50vw;
        margin-right: 30.625rem;
        right: 50%;
        left: auto;
    }
    #cta-1192:after {
        height: 17.6875rem;
        width: 10vw;
    }
    #cta-1192 .cs-container {
        padding-left: 13.5625rem;
        padding-right: 0;
    }
    #cta-1192 .cs-image {
        margin-left: clamp(2rem, 4.5vw, 5rem);
        left: 50%;
        right: auto;
    }
}

/*-- -------------------------- -->
<---          Reviews           -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #reviews-1192 {
        padding: var(--sectionPadding);
        padding-top: 0;
    }
    #reviews-1192 .cs-container {
        width: 100%;
        /* changed to 1280px at tablet */
        max-width: 34.375rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    #reviews-1192 .cs-picture {
        width: 100%;
        max-width: 46.125rem;
        /* 296px - 493px */
        height: clamp(18.5rem, 37vw, 30.8125rem);
        /* -75px to -120px, makes it overlap the CTA section above, changes at tablet */
        margin-top: calc(clamp(4.6875rem, 13vw, 7.5rem) * -1);
        display: block;
        position: relative;
        z-index: 10;
    }
    #reviews-1192 .cs-picture img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    #reviews-1192 .cs-review-group {
        width: 90%;
        max-width: 39.375rem;
        /* makes it overlap the image, removed at tablet */
        margin-top: -2.625rem;
        /* 24px - 80px */
        padding: clamp(1.5rem, 5.5vw, 5rem);
        /* prevents padding and border from affecting height and width */
        box-sizing: border-box;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        position: relative;
        z-index: 10;
    }
    #reviews-1192 .cs-icon {
        /* 48px - 97px */
        width: clamp(3rem, 7vw, 6rem);
        height: auto;
        display: block;
    }
    #reviews-1192 .cs-review-text {
        /* 14px - 16px */
        font-size: clamp(0.875rem, 1.5vw, 1rem);
        line-height: 1.5em;
        text-align: center;
        margin: 0;
        color: var(--bodyTextColor);
    }
    #reviews-1192 .cs-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;
    }
    #reviews-1192 .cs-profile {
        width: 3.125rem;
        height: auto;
        border-radius: 50%;
        border: 4px solid #fff;
        background-color: #fff;
    }
    #reviews-1192 .cs-name {
        /* 20px - 25px */
        font-size: clamp(1.25rem, 2vw, 1.5625rem);
        line-height: 1.2em;
        font-weight: 700;
        margin: 0;
        color: var(--headerColor);
        display: block;
    }
    #reviews-1192 .cs-desc {
        /* 14px - 16px */
        font-size: clamp(0.875rem, 1.5vw, 1rem);
        line-height: 1.5em;
        text-align: center;
        margin: 0;
        color: var(--bodyTextColor);
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #reviews-1192 .cs-container {
        max-width: 80rem;
        flex-direction: row;
        justify-content: flex-end;
    }
    #reviews-1192 .cs-picture {
        /* changes at desktop */
        width: 47%;
        margin: 0;
        position: absolute;
        top: calc(clamp(4.6875rem, 13vw, 7.5rem) * -1);
        left: 0;
    }
    #reviews-1192 .cs-review-group {
        width: 57%;
        margin: 0;
    }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    #reviews-1192 .cs-picture {
        width: 55%;
    }
}

                                