.feedback-wrap {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: var(--eggshell);
}

.form-header {
    box-sizing: border-box;
    font-size: 1.5rem;
    font-family: var(--main-font);
    color: #000;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    width: 100%;
    max-width: 550px;
}

.adin-logo {
    width: 60px;
    margin: 10px auto;
    cursor: pointer;
}

.feedback-form {
    margin: 0 auto 0 auto;
    height: auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.feedback-btn {
    float: inline-end;
    padding: 12px 24px;
    background-color: #ffffff;
    border: 1px solid #000;
    color: #000;
    font-weight: 600;
    /* border: none; */
    /* border-radius: 10px;  */
    cursor: pointer;
    font-size: 16px; 
    font-family: 'Helvetica Neue', Arial, sans-serif;
    /* margin-top: 25px; */
    width: 60%; 
    line-height: 1.6; 
    transition: 0.2s ease-in-out;
}

.feedback-btn:hover {
    background: #000;
    color: var(--eggshell);
}