.disclosures-wrap {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-position: -12px -12px;
    background-position: center;
    background-repeat: no-repeat;
    gap: 30px;
    padding-top: 85px;
    box-sizing: border-box;
    overflow: scroll;
}

.disclosures-wrap::-webkit-scrollbar {
    display: none;
}

.disclanding-wrap {
    box-sizing: border-box;
    width: 60vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    gap: 4px;
}

.disclosures-landing {
    margin: 15px 0 0 0;
    /* background-color: rgba(0, 0, 0, 0.349); */
    /* border: 1px solid #666666; */
    color: #000;
    font-family: var(--main-font);
    font-size: 2.25rem;
    /* margin: 0 auto; */
    width: 100%;
    display: flex;
    flex-direction: column;
    display: flex;
    white-space: nowrap;  
}

.comingsoon {
    font-family: var(--main-font);
    color: var(--sharp-red);
    text-align: right;
    font-size: 1rem;
    font-style: italic;
    text-shadow: 0 0 5px;
    font-weight: 700;
    line-height: 1;
}

.pdf-viewer-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* max-height: 100vh; */
    /* max-height: 600px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.pdf-viewer {
    box-sizing: border-box;
    /* border: #000 inset 2px; */
    width: 100%;
    height: 100%;
}

.disc-text {
    font-size: 1rem;
    font-family: var(--main-font);
    color: #ff0000;
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* margin-bottom: 3px; */
}

.pvents {
    pointer-events:fill;
}

.disclosures-landing p {
    font-size: 1rem;
    font-family: var(--main-font);
    /* font-weight: bold; */
    width: 100%;
    margin-top: 3px;
    color: #363636;
    /* text-align: center; */
    font-style: italic;
}

.disc-logo {
    width: 1.25rem;
    /* margin: auto auto auto 0 */
    margin-right: 6px;
}

.disc-section {
    box-sizing: border-box;
    white-space: normal;
    background-color: #1b1b1b9a; 
    background: linear-gradient(90deg, #80808080, #cecece80, #80808080);
    background: #FFF;
    /* background: #FFF; */
    padding: 15px;
    box-shadow: 2px 4px 6px 0 #0000002a;
    width: 50%;
    max-width: 550px;
    line-height: 1.6; 
    margin: auto;
    margin-bottom: 100px;

    border-radius: 8px;
    padding: 30px;
    /* border: 1px solid #00000021; */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.sign-btn {
    padding: 12px 24px;
    background-color: #ffffff;
    border: 1px solid #ff0000;
    color: #000;
    font-weight: 600;
    /* border: none; */
    /* border-radius: 10px;  */
    cursor: pointer;
    font-size: 16px; 
    font-family: 'Helvetica Neue', Arial, sans-serif;
    /* margin-top: 25px; */
    width: 100%; 
    line-height: 1.6; 
    transition: 0.2s ease-in-out;
}

.sign-btn:hover {
    background: #ff000e;
    border: 1px solid #fff;
    color: #fff;
}

@media only screen and (max-width: 900px) {
    .disclanding-wrap {
        width: 80%;
        margin-top: 60px;
    }
}   

@media only screen and (max-width: 650px) {
    /* .disclanding-wrap {
        width: 90%;
    } */
    .disc-section {
        width: 100%;
    }
}

@media only screen and (max-width: 475px)  {
    .disclosures-wrap {
        padding: 10px 0 0 0;
    }

    .disclosures-landing {
        font-size: 1.75rem;
    }
    .disclanding-wrap {
        width: 95%;
    }
}
