
.footer-wrap {
    box-sizing:border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    padding: 4px 4px;
    background: #fff;
    border-top: 2px solid #000;
    /* box-shadow: 0 0 8px #000; */
}

.footer-container {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.footer-text {
    font-family: var(--main-font);
    color: #000;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.white-emphasis {
    color: #000;
    text-align: right;
}

.footer-logo {
    margin: auto 0;
    width: 14px;
    height: 13px;

    /* height: 40px; */
  }

  .footer-hide {
    visibility: hidden;
  }

  .web-design-title {
    /* width: auto; */
    font-family: "monospace", monospace;
    margin: auto 0 auto 0;
    color: #2e2e2e2f;
    font-size: 10px;
    align-self: flex-end;
    cursor: pointer;
    text-decoration: none;
    transition: 0.1s ease-in-out;
  }

  .web-design-title:hover {
    color: #929292;
    font-weight: 700;
    text-shadow: 0 0 3px var(--eggshell);
  }

  @media only screen and (max-width: 500px)  {
    .footer-hide {
        display: none;
    }
}