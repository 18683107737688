.home-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-blend-mode: luminosity; */
    background-position: -12px -12px;
    background: linear-gradient(90deg, #fff , #eeeeee, #fff);
    background: var(--eggshell);
    backdrop-filter: blur(4px);
    background-position: center;
    /* background-size: 500px; */
    background-repeat: no-repeat;
    gap: 30px;
    overflow: hidden;
    -webkit-overflow-scrolling: hidden;
}

.background-movement {
    content: "";
    width: 150vw;
    height: 150vh;
    left: 0;
    top: -60vh;
    z-index: -2;
    /* mix-blend-mode: overlay; */
    /* mix-blend-mode:soft-light; */
    /* mix-blend-mode: luminosity; */
    opacity: 0.2;

    
    pointer-events: none;
    position: absolute;
    /* background-position: center; */
    /* background-position: 0% 0%; */
    background-repeat: no-repeat;
    /* background-position: center 700px; */
    background-size: contain;
    background-position: left;

    /* background-position: center -80vh; */

    /* background-size: cover; */
    border-radius: 8px;
    background-image: url(../../assets/logosvg.svg);
    filter: grayscale(1);

    overflow: visible;
}

.animate-class {
    animation: movebg 16s linear;
}

/* @keyframes movebg {
    0% {
        left: -100vw;
    }
    100% {
        left: 150vw;
    }
} 
*/

@media (orientation: landscape) {
    @keyframes movebg {
        0% {
            /* transform: translateX(-150vw); */
            left: -150vw;
        }
        100% {
            /* transform: translateX(150vw); */
            left: 150vw;
        }
    }
}

@media (orientation: portrait) {
    @keyframes movebg {
        0% {
            /* transform: translateX(-150vw); */
            left: -160vw;
        }
        100% {
            /* transform: translateX(150vw); */
            left: 150vw;
        }
    }
}

@media (min-width: 1920px) and (max-width: 2560px)  {
    @keyframes movebg {
        0% {
            /* transform: translateX(-150vw); */
            left: -100vw;
        }
        100% {
            /* transform: translateX(150vw); */
            left: 150vw;
        }
    }
}

.logo-home {
    width: 50px;
    /* height: 36px; */
    margin: 0 auto 35px 50px;
    filter: grayscale(1);

    /* margin: auto auto 0 auto; */
    /* align-self: flex-end; */
    /* left: 0;
    bottom: 0;
    position: fixed;
    margin: 20px; */
}


.path-list-wrap {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
}

.pathlist {
    margin: 0 auto;
    width: 50%;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.pathlist-item {
    font-size: 1.4rem;
    text-decoration: none;
    outline: 0;
    font-weight: 500;
    color: var(--black-color);
    cursor: pointer;
    display: flex;
    text-align: center;
    vertical-align: middle;
    margin: auto 0;
 
    -webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .2s ease-in-out;
}

.pathlist-item:hover {
    color: var(--secondary-color);
}


.fake-active {
    font-size: 1.6rem;
    text-align: center;
    color: #000;
    line-height: 1;
    font-weight: 700;
}

/* .fake-active::before {
    display: flex;
    content: "";
    background-color: var(--secondary-color);
    border-radius: 2px;
    width: 8px;
    margin: auto 3px auto 0px;
    height: 16px;
} */

.fit-active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.bruh {
    display: flex;
    content: "";
    background-color: #000;
    border-radius: 2px;
    width: 8px;
    /* margin: auto 3px auto 0px; */
    margin: auto 0;
    box-sizing: border-box;
    height: 16px;
    transform: translateY(1px);
}

.landing-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    gap: 10px;
}

.home-landing {
    /* padding: 15px; */
    /* background-color: rgba(0, 0, 0, 0.349); */
    /* border: 1px solid #666666; */
    color: var(--cool-grey);
    font-family: var(--main-font);
    font-size: 3.5rem;
    text-shadow: 0px 0px 5px;
    /* margin: 0 auto; */
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 1;

    white-space: nowrap;  
}

.welcome-text {
    color: var(--cool-grey);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
}

.adinqra-realty-wrap {
    display: flex;
    flex-direction: column;
}

.adinqra-emphasis {
    color: #FF0000;
    /* line-height: 1; */
}

.adinsubtext {
    color: var(--sharp-red);
    text-align: right;
    font-size: 1rem;
    font-style: italic;
    /* line-height: 0; */
}

.get-started-wrap {
    margin: 0 auto;
    font-family:  monospace;
    color: var(--accent-gold);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.please-fill {
    font-size: 1.2rem;
    text-align: center;
    /* border-top: 2px dashed #fff; */
}

.get-started-wrap b {
    font-family: var(--main-font);
    text-shadow: 0 0 4px;
    /* font-style: italic;  */
}

.get-started-btn {
    font-size: 1rem;
    color: #fff;
    font-family: var(--main-font);
    font-weight: 700;
    background-color: #0000007e;
    border: 1px solid var(--sharp-red);
    margin: 0 auto;
    padding: 10px;
    max-width: 50%;
    transition: 0.25s ease-in-out;
    cursor: pointer;
    border-radius: 0 10px 0 10px;
}

.get-started-btn:hover {
    background: #ffffff7e;
    border: 1px solid var(--neon-blue);
    box-shadow: 0px 0px 2px var(--neon-blue);
    color: #000;
}

@media only screen and (max-width: 475px)  {
    .home-wrap {
        /* background-size: 250px; */
    }

    .background-movement {
        /* scale: 1.5; */
        /* background-size: auto 150%; */
        /* background-position: center; */
        /* background-clip:border-box; */
    }

    @keyframes movebg {
        0% {
            transform: translateX(-150vw);
            /* left: -120vw; */
        }
        100% {
            transform: translateX(150vw);
            /* left: 80vw; */
        }
    }

    .home-landing {
        font-size: 1.85rem;
    }

    .please-fill {
        font-size: 0.8rem;
    }

    .adinsubtext {
        font-size: 0.8rem;
    }

    .get-started-btn {
        font-size: 0.7rem;
    }

    .pathlist {
        flex-direction: column;
        align-items: center;
    }

    .logo-home {
        width: 36px;
        margin: 0 auto 20px 20px;
    }
}

@media only screen and (max-width: 675px) and (min-width: 475px)  {
    .home-wrap {
        /* background-size: 400px; */
    }

    .home-landing {
        font-size: 2.5rem;
    }

    .please-fill {
        font-size: 1rem;
    }

    .pathlist {
        flex-direction: column;
        align-items: center;
    }

}