
.form-wrap {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    /* font-family: 'Satoshi', sans-serif; */
    font-family: "Open Sans", sans-serif;

    background-size: 100%;
    /* background: url(../../assets/softadinqra.webp), linear-gradient(90deg, #d6d6d6 5%, #fff, #d6d6d6 95%); */
    /* background: linear-gradient(90deg, #fff , #eeeeee, #fff); */
    background: var(--eggshell);
    background-position: center;
    /* background-size: 500px; */
    background-repeat: no-repeat;
    /* background: url("./bg.webp"); */
    /* background-repeat: no-repeat;
    background-size: cover; */
    white-space: nowrap; 
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.form-wrap::-webkit-scrollbar {
    display: none;
}

.form-content {
    box-sizing: border-box;
    width: 50%;
    max-width: 550px;
    height: auto;
    margin: 0 auto;

    color: #000000; 
    display: flex;
    flex-direction: column; 
    justify-content: start;
    gap: 16px;
    /* align-items: center; */
    min-height: 100vh;
    white-space: nowrap; 
    line-height: 1.6;
     
    /* overflow: scroll; */
    -webkit-overflow-scrolling: touch;
    padding: 0px 0 100px 0;
}

.form-content::-webkit-scrollbar {
    display: none;
}

.form-header {
    box-sizing: border-box;
    /* font-family: 'Satoshi', monospace; */
    font-size: 1.5rem;
    font-family: var(--main-font);
    color: #000;
    /* text-align: center; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    width: 50%;
    max-width: 550px;
}

.adin-logo {
    width: 60px;
    margin: 10px auto;
    cursor: pointer;
}

.header-text {
    font-weight: bold;
    font-family: var(--main-font);
    text-align: center;
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
}

.form-section {
    box-sizing: border-box;
    white-space: normal;
    background-color: #1b1b1b9a; 
    background: linear-gradient(90deg, #80808080, #cecece80, #80808080);
    background: #FFF;
    /* background: #FFF; */
    padding: 15px;
    box-shadow: 2px 4px 6px 0 #0000002a;
    width: 100%;
    line-height: 1.6; 
    margin: auto;

    border-radius: 8px;
    padding: 30px;
    /* border: 1px solid #00000021; */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.form-section::before {
    content: "";
    height: 100%;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 8px;
    /* background-image: url(../../assets/metal-texture.webp); */
}
/* 
.form-section::after {
    content: "";
    height: 100%;
    left: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    border-radius: 8px;
    opacity: 0.3;
    filter: blur(3px);
    background-image: url(../../assets/metalbg-texture.webp);
} */

.section-header {
    color: #000000;
}

.group-fields {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input-field {
    /* position: relative;
    border-bottom: 2px solid #e9e9e9;
    margin: 6px 0;
    width: 100%;


    border-radius: 10px;
    border: 1px solid lightgrey; */
}

.input-field label {
    /* position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #000;
    font-size: 16px;
    pointer-events: none;
    transition: 0.15s ease; */
}

.input-field input {
    /* width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #000; */
}

.input-field input:focus~label,
.input-field input:valid~label {
    /* font-size: 0.8rem;
    top: 10px;
    transform: translateY(-120%); */
}

.horizontal-input {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    text-overflow: clip;
    gap: 8px;
    justify-content: space-between;
}

.flex-end {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.vertical-input {
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* vertical-align: middle; */
}

.horizontal-input label {
    float: left;
    word-wrap:break-word;
    /* width: 30%; */
    /* margin: auto 0; */
    color: #000000;
}

.vertical-input label {
    float: left;
    word-wrap:break-word;
    margin: auto 0;
    color: #000000;
}

.question-section {
    background-color: #EFEFEF; 
    padding: 15px 25px;
    margin-top: 40px;
    border-radius: 10px; 
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.295);
    line-height: 1.6; 
}

.justify-text-right {
    text-align: right;
    margin: auto 0;
    width: 15%;
}

.justify-text-left {
    text-align: left;
}

.horizontal-input select, input {
    padding: 2px 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    /* line-height: 1.6; */
    justify-content: right;
    /* text-align: right; */
    /* background: #f9fafb; */
    color: #000000;
    /* width: min-content; */
}

.areas-of-interest-field {
    margin: 0 auto;
    padding: 2px 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background: #FFF;
    color: #000000;
    width: min-content;
    resize: none;
    width: 100%;
}

.end-form-gap {
    /* margin-bottom: 140px; */
}


/* input[type="checkbox"], input[type="radio"] {
    width: auto;
    margin-right: 10px;
    cursor: pointer;
    } */
    
    .checkbox-group, .radio-group {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }
    
    .checkbox-column, .radio-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
    }
    
    .submit-button {
        padding: 12px 24px;
        background-color: #ffffff;
        border: 1px solid #000;
        color: #000;
        font-weight: 600;
        /* border: none; */
        /* border-radius: 10px;  */
        cursor: pointer;
        font-size: 16px; 
        font-family: 'Helvetica Neue', Arial, sans-serif;
        /* margin-top: 25px; */
        width: 100%; 
        line-height: 1.6; 
        transition: 0.2s ease-in-out;
    }
    
    .submit-button:hover {
        background-color: #000;
        color: var(--eggshell);
    }
    
    .submit-button.pop {
        transform: scale(1.1);
        background-color: #f50404; 
        color: #000fff; 
        border: 1px solid #000fff;
    }
    
    .checkbox-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #000000;
        
    }

    .terms-wrap {
        display: flex;
        flex-direction: row;
        /* flex-wrap: wrap; */
        text-overflow: clip;
        gap: 20px;
        justify-content: space-between;
    }

    .extendbar {
        border: 1px solid var(--cool-grey);
        width: 100%;
        margin: auto 0 0 0;
    }
                
    @media screen and (max-width: 900px) {
        .form-content {
            width: 80%;
        }

        .form-section {
            width: 100%;
            /* max-width: 450px; */
        }

        .form-header {
            width: 80%;
            max-width: 450px;
        }
    }
    
    @media only screen and (max-width: 675px)  {
        .form-header {
            font-size: 1.2rem;
        }

        .form-header {
            margin: 60px auto 0 auto;
        }
    }

    @media only screen and (max-width: 550px)  {
        .form-content {
            width: 92%;
        }

        .form-section {
            width: 100%;
            padding: 20px 16px;
        }

        .form-header {
            width: 92%;
            font-size: 1.1rem;
            margin: 20px auto 0 auto;
        }

        .horizontal-input {
            flex-wrap: wrap;
            /* gap: 0; */
        }
    }
