:root {
  --active-color: #000;
  --secondary-color: #000; /*ffec00*/
  --black-color: #000;
  /* --nav-font: 'Kode Mono', monospace; */
}

.navbar-top {
  font-family: var(--main-font);
  font-size: 20px;
  /* width: 100%; */
  /* letter-spacing: 2px; */
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  right: 0;
  top: 0;
  width: 202px;
  /* height: 85px; */
  /* background-color: #000000a9; */
  /* box-shadow: 0 0 10px #000; */
}

  .nav-interwrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    
  }

  .navlist {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    list-style-type: none;
    gap: 6px;

    margin: 26px 26px 0 0;
    padding: 5px 7px 5px 7px;
    border: none;
    outline: none;

    /* background-color: #fff; */
    /* border-radius: 6px; */
  }

  .logo-title-wrap {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 15px;
    /* margin-left: 25px; */
    /* justify-content: space-evenly; */
    color: #fff
  }

  .logo {
    /* margin: auto; */
    margin: 10px;
    width: 40px;
    height: 36px;
    /* height: 40px; */
  }
  
  .navlist-item {
    /* margin: 0 20px; */
    color: #fff;

    /* margin: auto; */
    padding: 5px;
    border: none;
    outline: none;
    display: inline-block;
    padding: 8px 13px;
    /* line-height: 32px; */
    text-decoration: none;
    text-align: left;
    font-size: 1.2rem;

    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    cursor: pointer;
  }

  .navlist-spacer {
    color: #000;
    margin: auto;
    padding: 5px;
    border: none;
    outline: none;
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;
  }

  /* nav li a {
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;

    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;

    color: #fff;
  } */

  .navlist-item, .navlist-item:visited {
    text-decoration: none;
    outline: 0;
    font-weight: 500;
    color: var(--black-color);

    display: flex;
 
    -webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .2s ease-in-out;
 }
 
 .navlist-item:hover, .navlist-item:focus { color: #fff; }
  
  .navlist-item.active {
    /* background-color: transparent !important; */
   color: var(--active-color); 
   font-size: 1.4rem;
   line-height: 1;
   font-weight: 700;
   background: none;
   /* border-radius: 4px; */
   /* text-shadow: 0 0 2px var(--active-color); */
  }

  .small-navlist-item.active {
    /* background-color: transparent !important; */
   color: var(--active-color); 
   font-weight: 600;
   /* text-shadow: 0 0 4px var(--active-color); */
  }

  .navlist-item:hover {
    transition: 0.25s ease-in-out;
    color: var(--active-color); 
  }


  .navlist-item.active::before { 
    display: flex;
    content: "";
    background-color: var(--secondary-color);
    border-radius: 2px;
    width: 8px;
    margin: auto 4px auto 0px;
    height: 14px;
    box-shadow: 0 0 10px #fff;
  }

  .small-navlist-item {
    color: #fff;

    margin: auto;
    padding: 5px;
    border: none;
    outline: none;
    display: inline-block;
    padding: 8px 13px;
    /* line-height: 32px; */
    text-decoration: none;
    text-align: left;

    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    cursor: pointer;
    

    margin: 0.75rem 0.5rem;
    cursor: pointer;
    color: white;
    font-size:1.6rem;
    text-align: right;
  }

  .small-navlist-item.active .small {
    margin-left: auto;
    /* width: min-content; */
    justify-content: right;
    display: flex;
  }

  .small-navlist-item.active .small::before { 
    display: flex;
    content: "";
    background-color: var(--secondary-color);
    border-radius: 2px;
    width: 8px;
    margin: auto 4px auto 0px;
    height: 14px;
    transform: translateY(1px);
  }

.app__navbar-smallscreen {
  display: none;
  box-sizing: border-box;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background: rgba(0, 0, 0, 0.8); */
  margin: auto;
  color: var(--active-color);
  font-size: 45px;
  box-sizing: border-box;
  /* border-radius: 4px; */
  padding: 4px;
  /* background: #fff; */
  /* border: 4px outset #fff; */
  /* background: #000; */
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    transition: 0.5s ease-in-out;
    flex-direction: column;
    /* z-index: 5; */
}

.app__navbar-smallscreen_overlay .overlay__close {
    color: #fff;
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
}
/* 
.app__navbar-smallscreen_links li {
    margin: 1rem;
    cursor: pointer;
    color: white;
    font-size: 2rem;
    text-align: right;
} */

.app__navbar-smallscreen_links li:hover {
    color: var(--active-color);
}

.overlay__close {
  color: #fff;
}

  @media only screen and (max-width: 800px)  {
    .navbar-top {
      width: auto;
    }

    .nav-interwrap {
      display: none;
    }

    .app__navbar-smallscreen {
      box-sizing: border-box;
      /* position: fixed; */
      display: flex;
      right: 0;
      padding: 20px 20px 0 0;
      /* margin: 20px */

     }

     .logo-title-wrap h1 {
      font-size: 1.4rem;
     }

    
  }