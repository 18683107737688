@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

:root {
  --main-color-black: #FFFFFF;
  --accent-purple: #5D536B;
  --cool-grey: #000;
  --bright-green: #B3EFB2;
  --sharp-red: #FF8F8F;
  --accent-gold: #FF0000;
  --neon-blue: #00FFFF;

  --eggshell: #F0EAD6;

  --main-font: 'Helvetica', 'Arial', sans-serif;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  background-image: #00ff00;
  /* overflow-x: hidden; */
/* 
  background-color: var(--main-color-black);
  background-size: cover; */
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-form {
  display: none;
  visibility: hidden;
}
